import { toBoolean } from 'helpers/environment';
import { defaultTo } from 'helpers/utils';
import { LogLevel } from 'types/log';

const { REACT_APP_AUTH_DISABLED,
  REACT_APP_AUTH_SIGN_IN_REDIRECT_URL, REACT_APP_AUTH_SIGN_OUT_REDIRECT_URL } = process.env;

let defaultRedirectUnless;
let b2cPolicies;

const env = 'dev';
console.log(typeof env);

switch (String(env)) {
  case ('dev'): {
    console.log('Made It to DEV');
    defaultRedirectUnless = defaultTo('https://sketch.acidev.io/');
    b2cPolicies = {
      names: {
        signUpSignIn: 'B2C_1A_FA_EIDv2_Signin',
        forgotPassword: 'B2C_1A_FA_EIDv2_forgotpassword',
        editProfile: 'B2C_1A_FA_EIDv2_ProfileEdit',
        forgotUsername: 'B2C_1A_FA_EIDv2_ForgotUsername',
        signUp: 'B2C_1A_FA_EIDv2_Signup',
        b2esignin: 'B2C_1A_B2E_Signin'
      },
      authorities: {
        signUpSignIn: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_Signin',
        },
        forgotPassword: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_forgotpassword',
        },
        editProfile: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_ProfileEdit',
        },
        signUp: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_Signup',
        },
        forgotUsername: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_ForgotUsername',
        },
        b2esignin: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_B2E_Signin',
        }
      },
      authorityDomain: 'dev.login.firstam.com'
    };
    break;
  }

  case ('test'): {
    console.log('Made It to TEST');
    defaultRedirectUnless = defaultTo('https://sketch.acitest.io/');
    b2cPolicies = {
      names: {
        signUpSignIn: 'B2C_1A_FA_EIDv2_Signin',
        forgotPassword: 'B2C_1A_FA_EIDv2_forgotpassword',
        editProfile: 'B2C_1A_FA_EIDv2_ProfileEdit',
        forgotUsername: 'B2C_1A_FA_EIDv2_ForgotUsername',
        signUp: 'B2C_1A_FA_EIDv2_Signup',
        b2esignin: 'B2C_1A_B2E_Signin'
      },
      authorities: {
        signUpSignIn: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_Signin',
        },
        forgotPassword: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_forgotpassword',
        },
        editProfile: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_ProfileEdit',
        },
        signUp: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_Signup',
        },
        forgotUsername: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_ForgotUsername',
        },
        b2esignin: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_B2E_Signin',
        }
      },
      authorityDomain: 'dev.login.firstam.com'
    };
    break;
  }
  case ('uat'): {
    console.log('Made It to UAT');
    defaultRedirectUnless = defaultTo('https://sketch.aciuat.io/');
    b2cPolicies = {
      names: {
        signUpSignIn: 'B2C_1A_FA_EIDv2_Signin',
        forgotPassword: 'B2C_1A_FA_EIDv2_forgotpassword',
        editProfile: 'B2C_1A_FA_EIDv2_ProfileEdit',
        forgotUsername: 'B2C_1A_FA_EIDv2_ForgotUsername',
        signUp: 'B2C_1A_FA_EIDv2_Signup',
        b2esignin: 'B2C_1A_B2E_Signin'
      },
      authorities: {
        signUpSignIn: {
          authority: 'https://uat.login.firstam.com/myidaasuat.onmicrosoft.com/B2C_1A_FA_EIDv2_Signin',
        },
        forgotPassword: {
          authority: 'https://uat.login.firstam.com/myidaasuat.onmicrosoft.com/B2C_1A_FA_EIDv2_forgotpassword',
        },
        editProfile: {
          authority: 'https://uat.login.firstam.com/myidaasuat.onmicrosoft.com/B2C_1A_FA_EIDv2_ProfileEdit',
        },
        signUp: {
          authority: 'https://uat.login.firstam.com/myidaasuat.onmicrosoft.com/B2C_1A_FA_EIDv2_Signup',
        },
        forgotUsername: {
          authority: 'https://uat.login.firstam.com/myidaasuat.onmicrosoft.com/B2C_1A_FA_EIDv2_ForgotUsername',
        },
        b2esignin: {
          authority: 'https://uat.login.firstam.com/myidaasuat.onmicrosoft.com/B2C_1A_B2E_Signin',
        }
      },
      authorityDomain: 'uat.login.firstam.com'
    };

    break;
  }

  case ('prod'): {
    console.log('Made It to Default');
    defaultRedirectUnless = defaultTo('https://sketch.aciprod.io/');
    b2cPolicies = {
      names: {
        signUpSignIn: 'B2C_1A_FA_EIDv2_Signin',
        forgotPassword: 'B2C_1A_FA_EIDv2_forgotpassword',
        editProfile: 'B2C_1A_FA_EIDv2_ProfileEdit',
        forgotUsername: 'B2C_1A_FA_EIDv2_ForgotUsername',
        signUp: 'B2C_1A_FA_EIDv2_Signup',
        b2esignin: 'B2C_1A_B2E_Signin'
      },
      authorities: {
        signUpSignIn: {
          authority: 'https://login.firstam.com/firstamericanidentity.onmicrosoft.com/B2C_1A_FA_EIDv2_Signin',
        },
        forgotPassword: {
          authority: 'https://login.firstam.com/firstamericanidentity.onmicrosoft.com/B2C_1A_FA_EIDv2_forgotpassword',
        },
        editProfile: {
          authority: 'https://login.firstam.com/firstamericanidentity.onmicrosoft.com/B2C_1A_FA_EIDv2_ProfileEdit',
        },
        signUp: {
          authority: 'https://login.firstam.com/firstamericanidentity.onmicrosoft.com/B2C_1A_FA_EIDv2_Signup',
        },
        forgotUsername: {
          authority: 'https://login.firstam.com/firstamericanidentity.onmicrosoft.com/B2C_1A_FA_EIDv2_ForgotUsername',
        },
        b2esignin: {
          authority: 'https://login.firstam.com/firstamericanidentity.onmicrosoft.com/B2C_1A_B2E_Signin',
        }
      },
      authorityDomain: 'login.firstam.com'
    };

    break;
  }

  default: {
    defaultRedirectUnless = defaultTo('https://sketch2.acidev.io/dev');
    b2cPolicies = {
      names: {
        signUpSignIn: 'B2C_1A_FA_EIDv2_Signin',
        forgotPassword: 'B2C_1A_FA_EIDv2_forgotpassword',
        editProfile: 'B2C_1A_FA_EIDv2_ProfileEdit',
        forgotUsername: 'B2C_1A_FA_EIDv2_ForgotUsername',
        signUp: 'B2C_1A_FA_EIDv2_Signup',
        b2esignin: 'B2C_1A_B2E_Signin'
      },
      authorities: {
        signUpSignIn: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_Signin',
        },
        forgotPassword: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_forgotpassword',
        },
        editProfile: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_ProfileEdit',
        },
        signUp: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_Signup',
        },
        forgotUsername: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_FA_EIDv2_ForgotUsername',
        },
        b2esignin: {
          authority: 'https://dev.login.firstam.com/myidaasdev.onmicrosoft.com/B2C_1A_B2E_Signin',
        }
      },
      authorityDomain: 'dev.login.firstam.com'
    };
  }
}

const msalConfig = {
  auth: {
    clientId: '3a9d5f6c-b8d6-429c-b79b-26bbb8996db1', // This is the ONLY mandatory field that you need to supply. // sketch
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    redirectUri: defaultRedirectUnless(REACT_APP_AUTH_SIGN_IN_REDIRECT_URL),
    postLogoutRedirectUri: defaultRedirectUnless(REACT_APP_AUTH_SIGN_OUT_REDIRECT_URL), // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      loggerCallback: (level: unknown, message: unknown, containsPii: unknown) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.error:
            // eslint-disable-next-line no-console
            console.error(message);
            return;
          case LogLevel.info:
            // eslint-disable-next-line no-console
            console.info(message);
            return;
          case LogLevel.warn:
            // eslint-disable-next-line no-console
            console.warn(message);
            return;
          case LogLevel.debug:
            // eslint-disable-next-line no-console
            console.debug(message);
            break;
          default:
            // eslint-disable-next-line no-console
            // console.log(message);
        }
      }
    }
  }
};

export const authConfig = {
  enabled: !toBoolean(REACT_APP_AUTH_DISABLED),
  debugEnabled: false,
  msalConfig,
  b2cPolicies,
  eagleScope: 'https://MyIDaaSDev.onmicrosoft.com/9e65fafc-3d7f-4f76-9b7a-222d8ae42995/APIAccess',
};

// Old Cognito Configs
// region: appConfig.REACT_APP_AUTH_REGION,
// userPoolId: appConfig.REACT_APP_AUTH_USER_POOL_ID,
// userPoolWebClientId: appConfig.REACT_APP_AUTH_USER_POOL_CLIENT_ID,

// region: 'us-east-1',
// userPoolId: 'us-east-1_BTznfqKdv',
// userPoolWebClientId: '2u9ldh9srm4leffddrj0lhjc13',

// domain: appConfig.REACT_APP_AUTH_DOMAIN,
// redirectSignIn: appConfig.REACT_APP_AUTH_SIGN_IN_REDIRECT_URL,
// redirectSignOut: appConfig.REACT_APP_AUTH_SIGN_OUT_REDIRECT_URL,

// automaticSilentRenew: true,
// silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,

// export const STORAGE_AUTH_KEY = `oidc.user:${'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_BTznfqKdv/'}:${'1ivc6m7eba3eb1e3apj803uotq'}`;
